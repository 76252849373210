import "./styles.css";

import { Button, Grid, TextField } from "@mui/material";
import React, {useState} from "react";

import { LottieScrollSection } from "react-lottie-tools";
import animationData from "./static/data.json";
import client1Data from "./static/data.json";
import client2Data from "./static/data.json";

import { useEffect } from 'react';
import '@n8n/chat/style.css';
import { createChat } from '@n8n/chat';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

const clientsData = [client1Data, client2Data];
const script = {
  welcome: {
    mapping: {
      companyName: '[Client Name]'
    }
  },
  rebateIntelligence: {
    mapping: {
      title: "Client Rebate Intelligence",
      rawRecordsCount: "50037",
      brandAdminCount: "49175",
      genericAdminCount: "37500",
      finalAdministrationCount: "44588",

      total: "1000000",
      totalAdministrationCount: "44700",
      totalRebatableAdminCount: "1,011,400",
      annualRebateEstimate: "$51,480,160",
      rebatePerScript: "$981",

      rebateEstimateLabel: "Rebate Estimate",
      totalRebatableRecordCountLabel: "Total Rebatable Record Count",
      excludedMedicaidEstimateLabel: "Excluded Medicaid Estimate",
      totalRebatableRecordCountValue: "26,230"
    },
    clients: [
      {
        title: "Client 1 Rebate Intelligence",
        rawRecordsCount: "26677",
        brandAdminCount: "8852",
        genericAdminCount: "17815",
        finalAdministrationCount: "2685",

        total: "1248158",
        totalAdministrationCount: "26677",
        totalRebatableAdminCount: "2,685",
        annualRebateEstimate: "$4,992,632",
        rebatePerScript: "$464",

        rebateEstimateLabel: "Client 1 Rebate Estimate",
        excludedMedicaidEstimateLabel: "Total Rebatable Admin Count",
        totalRebatableRecordCountLabel: "",
        totalRebatableRecordCountValue: "",

        powerBiLink: "https://app.powerbi.com/view?r=eyJrIjoiMjk3ZDJkNTEtNmRjMC00NzkwLTkzMDEtOGViZDMxNTQ4N2IyIiwidCI6ImYwZGQ3MTYzLWFjMWMtNDliMC1iYzhjLWVkMGViOGZhNDY4MSIsImMiOjN9"
      },
      {
        title: "Client 2 Rebate Intelligence",
        rawRecordsCount: "25191",
        brandAdminCount: "11428",
        genericAdminCount: "13763",
        finalAdministrationCount: "7008",

        total: "1017042",
        totalAdministrationCount: "7008",
        totalRebatableAdminCount: "2,417",
        annualRebateEstimate: "$4,068,168",
        rebatePerScript: "$420.78",

        rebateEstimateLabel: "Client 2 Rebate Estimate",
        excludedMedicaidEstimateLabel: "Total Rebatable Admin Count",
        totalRebatableRecordCountLabel: "",
        totalRebatableRecordCountValue: "",

        powerBiLink: "https://app.powerbi.com/view?r=eyJrIjoiNjhiYjQwMjYtOWJiYy00ZTYzLWFkODItMTVmNWJiOTQ0YmJhIiwidCI6ImYwZGQ3MTYzLWFjMWMtNDliMC1iYzhjLWVkMGViOGZhNDY4MSIsImMiOjN9"
      }
    ]
  }
}


const defaultValues = {
  companyName: "",
  clientName: "",
  data: animationData,
  clients: [] as {client: any, playScript: string}[],
  isRender: true
};

export const N8NChat = () => {
  useEffect(() => {
    createChat({
      webhookUrl: 'https://vmotion.app.n8n.cloud/webhook/1727c687-aed0-49cf-96af-e7796819fbb3/chat',
      webhookConfig: {
        method: 'POST',
        headers: {}
      },
      // target: '#n8n-chat',
      mode: 'window',
      // chatInputKey: 'chatInput',
      chatSessionKey: 'sessionId',
      metadata: {},
      showWelcomeScreen: false,
      defaultLanguage: 'en',
      initialMessages: [
      ],
      i18n: {
        en: {
          title: 'Hi there! 👋',
          subtitle: "My name is HEALTH WAVE AI",
          footer: '',
          getStarted: 'New Conversation',
          inputPlaceholder: 'Type your question..',
          closeButtonTooltip: ""
        },
      },
    });
  }, []);

  return (<div></div>);
};

const LottieControl = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      isRender: false
    });
  };

  const prepareData = (animationData: any, mapping: any, client: any) => {
    const animationDataString = JSON.stringify(animationData);

    const keys = Object.keys(mapping);
    const clientAnimationString = keys.reduce((accumulator, currentValue) => {
      return accumulator.replaceAll(mapping[currentValue], client[currentValue])
    }, animationDataString);

    return JSON.parse(clientAnimationString);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { companyName, clientName } = formValues;

    const data = prepareData(animationData, script.welcome.mapping,{companyName: clientName});
    const clients = clientsData.map((d, i) => {
      const mapping = script.rebateIntelligence.mapping;
      const client = script.rebateIntelligence.clients[i];

      return {
        client,
        playScript: prepareData(d, mapping, client)
      };
    });
    setFormValues({
      ...formValues,
      data,
      clients: clients,
      isRender: true
    });
    console.log(formValues);
    forceUpdate();
  };

  const openPowerBi = (powerBiLink: string) => {
    window.open(powerBiLink, "_blank");
  };

  return (
      // <Viewer />
      <div>
        {/*<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>*/}
        {/*  <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>*/}
        {/*    <div id="n8n-chat"></div>*/}
        {/*  </Paper>*/}
        {/*</Container>*/}
        {/*<div className="container-form">*/}
        {/*  <form onSubmit={handleSubmit}>*/}
        {/*    <Grid container alignItems="center" direction="row">*/}
              {/*<Grid item xs={2}>*/}
              {/*  <TextField*/}
              {/*    id="name-input"*/}
              {/*    name="companyName"*/}
              {/*    label="Company Name"*/}
              {/*    type="text"*/}
              {/*    value={formValues.companyName}*/}
              {/*    onChange={handleInputChange}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={2}>*/}
              {/*  <TextField*/}
              {/*      id="age-input"*/}
              {/*      name="clientName"*/}
              {/*      label="Client Name"*/}
              {/*      type="text"*/}
              {/*      value={formValues.clientName}*/}
              {/*      onChange={handleInputChange}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Button variant="contained" color="primary" type="submit">*/}
              {/*  Submit*/}
              {/*</Button>*/}
        {/*    </Grid>*/}
        {/*  </form>*/}
        {/*</div>*/}
        {/*{ formValues.isRender &&*/}
        {/*    <div>*/}
        <div>
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[0, 239]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[245, 510]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[520, 730]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[770, 960]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[970, 1220]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[1230, 1405]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[1410, 1670]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[1675, 1915]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[1920, 2180]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[2185, 2360]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[2375, 2630]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[2635, 2800]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[2815, 3065]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[3070, 3250]} />
          <LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[3255, 3500]} />
        </div>
        <N8NChat></N8NChat>
              {/*<LottieScrollSection className="slide" height={4000} animation={formValues.data} frames={[239, 460]} />*/}
              {/*<LottieScrollSection height={4000} animation={formValues.data} frames={[345, 503]} />*/}

              {/*{formValues.clients.map(({ client, playScript }) => {*/}
              {/*  return (*/}
              {/*      <div>*/}
              {/*        <LottieScrollSection height={4000} animation={playScript} frames={[680, 892]} />*/}
              {/*        <LottieScrollSection height={4000} animation={playScript} frames={[902, 1060]} />*/}
              {/*        <div onClick={() => openPowerBi(client.powerBiLink)}>*/}
              {/*          <LottieScrollSection className={"powerbi-scene"} height={4000} animation={playScript} frames={[1215, 1270]} />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*  )*/}
              {/*})}*/}

              {/*/!* GOODBYE SLIDE*!/*/}
              {/*<LottieScrollSection height={4000} animation={formValues.data} frames={[1175, 1215]} />*/}
              {/*/!* GOODBYE SLIDE*!/*/}

              {/*/!* DATA LOADER SLIDE*!/*/}
              {/*<LottieScrollSection height={4000} animation={formValues.data} frames={[507, 660]} />*/}
              {/*/!* DATA LOADER SLIDE*!/*/}
            {/*</div>}*/}
      </div>
  );
};

export default LottieControl;
